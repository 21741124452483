<template>
  <div class="Homepage">
    <!-- 头部菜单 -->
    <div class="nav-box" id="nav">
      <div class="container">
        <div class="logo">
          <img src="@/assets/img/wx-web-logo.png" alt="" />
          <!-- <img
            src="@/assets/img/opentextlogo.png"
            alt=""
            style="margin-bottom: 15px; margin-left: 15px"
          /> -->
        </div>
        <div class="menu">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            text-color="#333333"
            active-text-color="#0087D7"
          >
            <el-menu-item index="1" @click="linkswiper()">
              <!-- xxx参照下图的格式 -->

              首页</el-menu-item
            >
            <el-menu-item index="2" @click="linkserve()"> 服务</el-menu-item>
            <el-menu-item index="3" @click="linkcase()"> 案例 </el-menu-item>
            <el-menu-item index="4" @click="linkabout()">关于文信</el-menu-item>
            <el-menu-item index="5" @click="link()">联系我们</el-menu-item>
            <el-menu-item index="6" @click="linkclass()">典型客户</el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    <!-- 轮播图 -->
    <div class="swiper">
      <Carousel :items="items" :interval="interval" />
    </div>
    <!-- 服务模块 -->
    <div class="serve">
      <div
        class="main"
        ref="header"
        :class="{ 'ls-animationed': header_Animate }"
      >
        <div class="title">服务领域</div>
        <div class="title">Range Of Services</div>
        <div class="content">
        文信软件聚焦工程能源行业，依靠既有大量优质客户，核心团队超过15年的行业背景，以及国内自创新的大环境，打造出适用于石化、天然气、核电行业的工程、交付、运营阶段数字化管理平台，目标是成为国内一流面向工程能源行业产业链全寿期的数字化交付及数字运营管理产品的科技公司。
        </div>
        <div
          class="flexlist"
          ref="serve"
          :class="{ 'ls-animationed': serve_Animate }"
        >
                           <div class="box">
            <div class="box-title">工程能源行业</div>
            <div class="box-content">
              工程能源行业对在设计、建设、运营过程中，大量的产业链公司之间的交互、协同和合作，产生大量非结构化内容。有严格的内容分发、审批、流转等要求，依赖内容管理解决方案进行有效管理。
            </div>
          </div>
          <div class="box">

            <div class="box-title"> 核电</div>
            <div class="box-content">
              在核电，石油及天然气领域，我们有着多年的行业解决方案实施经验，无论是内容管理还是业务流程，我们都有着非常成熟的解决方案和实施过程。
            </div>
          </div>
          <div class="box">
            <div class="box-title">银行 & 金融</div>
            <div class="box-content">
              在银行及金融领域，我们曾经为国内众多银行提供影像系统建构的解决方案或实施过程。在这一领域我们不仅有非常成熟的案例和经验。也与客户保持了非常密切的合作关系。
            </div>
          </div>
   
        </div>
      </div>
    </div>
    <!-- 经典案例 -->
    <div
      class="case"
      id="case"
      ref="servely"
      :class="{ 'ls-animationed': servely_Animate }"
    >
      <div class="main">
        <div class="title">
          <h2>经典案例</h2>
          <p>CLASSIC CASE</p>
        </div>
        <div class="boxlist">
          <div class="box" v-for="(v, k) in ClassCaseList" :key="k">
            <div class="overbox">
              <!-- ClassCaseList -->
              <h3>{{ v.title }}</h3>
              <p>
                {{ v.content }}
              </p>
              <div class="btn" @click="openImg(v.id)">查看详情</div>
            </div>
          </div>
          <!-- <div class="box">
            <div class="overbox">
              <h3>台山核电3年系统开发项目</h3>
              <p>
                工程能源行业对在设计、建设、运营过程中，大量的产业链公司之间的交互、协同和合作，产生大量非结构化内容。有严格的内容分发、审批、流转等要求，依赖内容管理解决方案进行有效管理。
              </p>
              <div class="btn">查看详情</div>
            </div>
          </div>
          <div class="box">
            <div class="overbox">
              <h3>大鹏天然气项目</h3>
              <p>
                华润置地华侨城·侨晋一期总承包工程一标段项目：位于太原市万柏林区，
                北至九院沙河南抢险路，南至规划路，东至规划路，总建筑面积
                183834.51m，
                主要用途为住宅/商业/幼儿园/换热站/地下车库，住宅楼包括
                8#楼、10#楼、11# 楼、13#楼、14#楼、15#楼六栋主楼，其他包括 S-1
                商业、换热站。
              </p>
              <div class="btn">查看详情</div>
            </div>
          </div>
          <div class="box">
            <div class="overbox">
              <h3>中海壳牌项目</h3>
              <p>
                华润置地华侨城·侨晋一期总承包工程一标段项目：位于太原市万柏林区，
                北至九院沙河南抢险路，南至规划路，东至规划路，总建筑面积
                183834.51m，
                主要用途为住宅/商业/幼儿园/换热站/地下车库，住宅楼包括
                8#楼、10#楼、11# 楼、13#楼、14#楼、15#楼六栋主楼，其他包括 S-1
                商业、换热站。
              </p>
              <div class="btn">查看详情</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="aboutus">
      <div
        class="leftimg"
        ref="aboutusr"
        :class="{ fadeInRight: aboutusr_Animate }"
      >
        <img src="@/assets/img/aboutus.jpg" alt="" height="650px" />
      </div>
      <div
        class="infobox"
        ref="aboutusl"
        :class="{ fadeInLeft: aboutusl_Animate }"
      >
        <div class="overbox">
          <div class="title">
            <h2>关于文信</h2>
            <p>ABOUT WENXIN</p>
          </div>
          <div class="content">
          文信软件科技（广州）有限公司简称文信软件,立心科技作为文信的母公司，是一家在中国注册的专业从事内容管理解决方案的科技公司。公司成立于2017年5月，由原OpentText Documentum产品原厂专业咨询团队原班人马创建。文信软件核心成员均来自原EMC公司Documentum ECD（Enterprise Content Division）事业部以及Opentext（中文名：启信软件）公司，BMC公司、宝马汽车等在IT行业内从事10-20年的专家和高级顾问。专注于企业内容管理（ECM），以及衍伸领域，如工程信息管理、工程文件一体化管理、配置管理、合同管理、项目管理、金融业影像管理、医疗法规管理、药品提交注册等。公司提供专业的内容管理解决方案的同时，还基于平台本身，提供各种行业内特定的解决方案，在ECM上提供优化和改良以便更好的适应国内的客户习惯。
文信软件是国内文档管理经验最丰富的软件公司，同时也是一个仍在蓬勃发展的新型的软件公司，未来五年，文信软件将致力于发展为具有国际影响力的和自主核心竞争力的国际性软件公司，为中国软件业的发展作出自己的贡献，为客户提供最好的解决方案和产品，提供最优质高效的服务。文信软件的良好可持续发展的能力是保证长期客户服务品质的前提，文信软件将与客户共同发展。
          </div>
        </div>
        <img src="@/assets/img/infoboxbg.png" alt="" />
      </div>
    </div>
    <!-- 为什么选择文信 -->
    <div
      class="whychoose"
      ref="servefield"
      :class="{ 'ls-animationed': servefield_Animate }"
    >
      <div class="title">
        <h2>为什么选择文信</h2>
        <p>WHY CHOOSE US</p>
        <p>文信深耕IT服务行业,是您企业值得依赖的数字化转型合作伙伴</p>
      </div>
      <div class="content">
        <div class="left">
          <div class="title">
            <!-- <p>专注内容管理，深刻理解客户需求</p>  -->
            <!-- 拥有丰富的Documentum及Opentext产品系统的设计,开发,实施经验,为来自核电,石油及天然气领域等多个行业的客户，提供卓越服务 -->
            <p>立足于中国内容管理行业</p>
            最早从2003年开始从事该行业 <br />
            研发出符合国人习惯、功能丰富、具有创新性的产品
          </div>
          <div class="title-two">
            <p>强大的技术,管控能力卓越</p>
            长期服务于国内各大企业客户，对项目管控和执行能力有着丰富经验,
            Opentext的资深人士,严格执行国际安全与质量管控标准
          </div>
          <div class="title-three">
            <p>专注内容管理，深刻理解客户需求</p>
            拥有丰富的内容管理产品和数字化工厂系统的设计、开发、实施经验,为来自核电,石油及天然气领域等多个行业的客户，提供卓越服务
          </div>
        </div>
        <div class="middle">
          <img src="@/assets/img/whychooseus.jpg" alt="" width="100%" />
        </div>
        <div class="right">
          <div class="title">
            <p>专业团队，经验丰富</p>
            核心团队超过15年的行业背景 <br />
            资深项目经理高效管控项目各个环节
          </div>
          <div class="title-two">
            <p>助力企业数字化转型</p>
            借助大数据、区块链、数据可视化等先进技术，实现配置管理+文档管理系统,解决企业无关联数据不能直观展现及利用的问题
          </div>
          <div class="title-three">
            <p>囯家单位认可的自主研发企业</p>
       是国电投及下属9家单位系统运维服务商、台山核电系统开发运维服务商、中石化上海工程公司、中海壳牌、大鹏天然气、福建农信等实施服务商。
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div
      class="Business-partner"
      ref="partner"
      :class="{ 'ls-animationed': partner_Animate }"
    >
      <div class="title">
        <h2>典型工程能源客户名单</h2>
        <p>TYPICAL CUSTOMERS</p>
      </div>

        <div class="contenttwo">
        <div class="left">
          <div class="pic">
            <img
              src="@/assets/img/customer1.png"
              alt=""
              width="180px"
              height="85px"
            />
            <img
              src="@/assets/img/customer2.png"
              alt=""
              width="300px"
              height="85px"
              style="margin-left: 5px"
            />
          </div>
          <div class="pic">
            <img
              src="@/assets/img/customer3.png"
              alt=""
              width="500px"
              height="85px"
            />
          </div>
          <div class="pic">
            <img
              src="@/assets/img/customer4.png"
              alt=""
              width="500px"
              height="85px"
            />
          </div>
          <div class="pic">
            <img
              src="@/assets/img/customer5.png"
              alt=""
              width="500px"
              height="65px"
            />
          </div>
          <div class="pic">
            <img
              src="@/assets/img/customer6.png"
              alt=""
              width="500px"
              height="65px"
            />
          </div>
          <div class="pic">
            <img
              src="@/assets/img/customer7.png"
              alt=""
              width="500px"
              height="65px"
            />
          </div>
          <div class="pic">
            <img
              src="@/assets/img/customer8.png"
              alt=""
              width="500px"
              height="65px"
            />
          </div>
        </div>
        <div class="right">
          <div class="pic">
            <div class="l">
              <img
                src="@/assets/img/customer9.png"
                alt=""
                width="315px"
                height="85px"
              />
              <img
                src="@/assets/img/customer10.png"
                alt=""
                width="315px"
                height="85px"
              />
            </div>
            <div class="r">
              <img
                src="@/assets/img/customer11.png"
                alt=""
                width="360px"
                height="180px"
              />
            </div>
          </div>
          <div class="pic-two">
            <img
              src="@/assets/img/customer12.png"
              alt=""
              width="680px"
              height="85px"
            />
          </div>
          <div class="pic-two">
            <img
              src="@/assets/img/customer13.png"
              alt=""
              width="680px"
              height="65px"
            />
          </div>
          <div class="pic-two">
            <img
              src="@/assets/img/customer14.png"
              alt=""
              width="680px"
              height="65px"
            />
          </div>
          <div class="pic-two">
            <img
              src="@/assets/img/customer15.png"
              alt=""
              width="680px"
              height="65px"
            />
          </div>
          <div class="pic-two">
            <img
              src="@/assets/img/customer16.png"
              alt=""
              width="680px"
              height="65px"
            />
          </div>
        </div>
      </div>
      <hr width="90%" style="height: 1px" color="#e5e5e5" />
            <div class="title">
        <h2>其它行业客户案例</h2>
        <p>OTHER CUSTOMERS</p>
      </div>
          <div class="content">
        <div class="box">
          <!-- <div class="list-unstyled-l">
        <ul >
         <li><img src="@/assets/img/partner7.png" alt="" width="260px" height="180px"></li>

          
            <li><img src="@/assets/img/partner11.png" alt="" width="260px" height="180px"></li>
     
          </ul>
          </div> -->
  
     
          <!-- <img src="@/assets/img/partner.png" alt="">
                   <img src="@/assets/img/partner.png" alt="">
                            <img src="@/assets/img/partner.png" alt="">
             
                                     <img src="@/assets/img/partner.png" alt=""> -->
                                     <div class="list-unstyled">
       <ul >
            <li><img src="@/assets/img/partner.png" alt=""  /></li>
            <li>
              <img
                src="@/assets/img/partner1.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
            <li>
              <img
                src="@/assets/img/partner2.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
            <li>
              <img
                src="@/assets/img/partner3.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
            <!-- <li>
              <img
                src="@/assets/img/partner4.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li> -->

         <li>
              <img
                src="@/assets/img/partner9.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
            <li>
              <img
                src="@/assets/img/partner10.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
            <!-- <li>
              <img
                src="@/assets/img/partner13.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
            <li>
              <img
                src="@/assets/img/partner14.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li> -->
            <!-- <li>
              <img
                src="@/assets/img/partner15.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li> -->
            <li>
              <img
                src="@/assets/img/partner16.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
            <li>
              <img
                src="@/assets/img/partner17.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
            <li>
              <img
                src="@/assets/img/partner18.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
            <li>
              <img
                src="@/assets/img/partner19.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
            <li>
              <img
                src="@/assets/img/partner20.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
            <li>
              <img
                src="@/assets/img/partner21.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li>
     
            
        
              <!-- <li>
              <img
                src="@/assets/img/partner22.png"
                alt=""
                width="260px"
                height="80px"
              />
            </li> -->
          </ul>
                                     </div>
   
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="title">
        <div class="left">
          <div class="bgdd">
        销售电话: 13761392681
            <br />
            邮箱: david.bu@wenxinsoft.com <br />
       广州市番禺区南村镇捷顺路9号 上城国际2栋1116室 <br />
            沈阳市和平区和平北大街94号 星光大厦3楼 <br />
     
          </div>
        </div>
        <div class="right">
          <div class="phone-num">
            <div class="vx">
              <div class="l">
                <span
                  class="qy-wechat"
                  @mouseenter="enter(1)"
                  @mouseleave="leave(1)"
                  >企业微信</span
                >
              </div>
              <div class="r" v-if="iswechatimg">
                <img
                  src="@/assets/img/EnterpriseWechat.png"
                  width="90px"
                  alt=""
                />
              </div>
            </div>
            <br />
            <div class="vx">
              <div class="l">
                <span
                  class="qy-wechat"
                  @mouseenter="enter(2)"
                  @mouseleave="leave(2)"
                  >销售部</span
                >
              </div>
              <div class="r" v-if="iswechatimg2">
                <img
                  src="@/assets/img/wx-david.png"
                  width="90px"
                  alt=""
                />
              </div>
            </div>
            <br />
            <div class="vx">
              <div class="l">
                <span
                  class="qy-wechat"
                  @mouseenter="enter(3)"
                  @mouseleave="leave(3)"
                  >公司事务</span
                >
              </div>
              <div class="r" v-show="iswechatimg3">
                <img src="@/assets/img/wx-lei.png" width="90px" alt="" />
              </div>
            </div>
            <br />
            <div class="vx">
              <div class="l">
                <span
                  class="qy-wechat"
                  @mouseenter="enter(4)"
                  @mouseleave="leave(4)"
                  >研发中心</span
                >
              </div>
              <div class="r" v-show="iswechatimg4">
                <img src="@/assets/img/wx-jason.png" width="90px" alt="" />
              </div>
            </div>
          </div>
        </div>
              <div class="bottom-text">
   2017-2023 © 【版权所有】 文信软件科技（广州）有限公司 粤ICP备17079672号
      </div>
      </div>

   
    </div>
    <!-- 图片展示 -->
    <picture-view
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="srcList"
      :showpage="true"
    >
    </picture-view>
  </div>
</template>

<script>
import "@/assets/css/homepage.less";
import Carousel from "@/components/Carousel.vue";
import PictureView from "../components/PictureView.vue";
export default {
  name: "HomeView",
  components: {
    // HelloWorld
    Carousel,
    PictureView,
  },
  data() {
    return {
      iswechatimg: false,
      iswechatimg2: false,
      iswechatimg3: false,
      iswechatimg4: false,
      showViewer: false,
      srcList: [],
      ClassCaseList: [
        {
          id: 0,
          title: "中石化某工程公司项目",
          content: `项目文件管理系统作为中石化某工程公司数字化转型的重点项目；
2022年实施的项目文件管理系统一期，实现详细设计阶段的管理设计专业、供应商、信函、会议纪要和传送单的统一管理；
2023年计划实施的项目二期承上启下，扩展到前期工作部、技术管理部、质量安全部、采购部、施工管理部、工程技术档案等涉及到项目文件交换、审核、流转和存档的各个环节。
`,
          srcList: [
            {
              img: require("@/assets/img/casezsh1.png"),
              info: [
                { label: "asasa", value: "系统全貌介绍 —— 文档数据流转全过程" },
              ],
            },
            {
              img: require("@/assets/img/casezsh2.png"),
              info: [{ label: "asasa", value: "前期项目 —— 产业链视图" }],
            },
            {
              img: require("@/assets/img/casezsh3.png"),
              info: [{ label: "asasa", value: "前期项目 —— 内外部数据交换" }],
            },
            {
              img: require("@/assets/img/casezsh4.png"),
              info: [
                {
                  label: "asasa",
                  value: "详细设计 - 设计、管理文件 —— 工作方式转变",
                },
              ],
            },
            {
              img: require("@/assets/img/casezsh5.png"),
              info: [
                {
                  label: "asasa",
                  value: "详细设计 - 设计、管理文件 —— 功能清单",
                },
              ],
            },
            {
              img: require("@/assets/img/casezsh6.png"),
              info: [
                {
                  label: "asasa",
                  value: "详细设计 - 设计、管理文件 —— 发起人(同步图纸目录)",
                },
              ],
            },
            {
              img: require("@/assets/img/casezsh7.png"),
              info: [
                {
                  label: "asasa",
                  value:
                    "详细设计 - 设计、管理文件 —— 发布区展示(成品提交信息)",
                },
              ],
            },
            {
              img: require("@/assets/img/casezsh8.png"),
              info: [
                {
                  label: "asasa",
                  value: "详细设计 - 设计、管理文件 —— 发布区展示",
                },
              ],
            },
          ],
        },
        {
          id: 1,
          title: "某核电EPC工程公司三维模型归档与智能P&ID综合查询平台",
          content: ` 为解决该单位核心资产三维模型的保存、再利用、调阅、归档、安全保密以及模型的档案中性化和长期保存等诸多问题，公司在原有档案系统底层数据平台上建立一套符合工程人员使用习惯的三维模型归档平台，简称3DRMS，集中管理来自各个专业产生的各类模型数据以及智能P&ID，使3DRMS满足完整性、准确性、时效性、系统性的需要。`,
          srcList: [
            {
              img: require("@/assets/img/casehd1.png"),
              info: [
                {
                  label: "asasa",
                  value:
                    "四合一页面 —— 加快推进公司数字化建设，作为国内一流的核电EPC总承包单位，实现海量数据的归档、满足法规遵循的监管要求，以及从国产化角度和档案永久长期保存角度，实现模型脱离原生设计软件后的可查阅性，以及和院内智能P&ID系统的关联，并与相关的海量文件数据关联。",
                },
              ],
            },
            {
              img: require("@/assets/img/casehd2.png"),
              info: [
                {
                  label: "xxxx",
                  value:
                    "三维模型 —— 3DRMS系统采用前瞻性思路，由传统的档案系统管理的归档数据角度上升到数据全生命周期角度；管理维度从档案的单一维度，扩展到按照档案（文件）、按照设备、按照P&ID和按照模型等多维度的综合平台",
                },
              ],
            },
            {
              img: require("@/assets/img/casehd3.png"),
              info: [{ label: "xxxx", value: "3DRMS主体架构" }],
            },
          ],
        },
        {
          id: 2,
          title: "某液化天然气LNG接收站智能P&ID（数字工厂）项目",
          content: `为解决某液化天然气公司技术资料查找困难、技术文件未合理归档、更新不及时等诸多问题，公司建立一套符合工程人员使用习惯的技术信息管理系统，简称TIMS，集中管理来自项目和运营的重要技术资料。TIMS系统于2021年11月30日上线运行，技术资料库也在不断更新整理中。为加快推进公司数字化建设，公司于2022年成立了信息科技管理委员会，技术服务部提交了未来三年的数字化建设设想，其中一项任务是完成智能P&ID系统开发，并与3D模型实现同步。`,
          srcList: [
            {
              img: require("@/assets/img/casetrq1.png"),
              info: [
                {
                  label: "asasa",
                  value:
                    "综合展现页面——基于工厂对象、工厂PBS、模型、PID以及文件类型的多维度展现视图",
                },
              ],
            },
            {
              img: require("@/assets/img/casetrq2.png"),
              info: [
                {
                  label: "asasa",
                  value: "工艺物料流程总图（PFD）作为数字工厂导航",
                },
              ],
            },
            {
              img: require("@/assets/img/casetrq3.png"),
              info: [
                {
                  label: "asasa",
                  value:
                    "设备属性、设备所属PID、三维模型定位、设备关联的一体化展现发布平台",
                },
              ],
            },
            {
              img: require("@/assets/img/casetrq4.png"),
              info: [{ label: "asasa", value: "PID分图层展现" }],
            },
            {
              img: require("@/assets/img/casetrq5.png"),
              info: [{ label: "asasa", value: "PID在线批注和协同" }],
            },
          ],
        },
        {
          id: 3,
          title: "国内某核电研究设计院",
          content: `  文信软件以及其文信核心成员就职的前任公司EMC为该院提供10多年的技术服务，从2011年最初启动的EDMS（技转文件管理和档案管理），之后集团总部实施的EDRMS（电子文件档案管理平台），再到与工程公司合并后的两套EDRMS系统融合，经过十多年时间，目前的EDRMS已经成为了院内的重要业务平台，涵盖了公司EPCS全过程的管理，其中流程30多支，包括基本的文件编审批流程、生效流程、分发流程、供应商文件审查流程、承包商审查流程、设计变更、互提资料等等。`,
          srcList: [
            {
              img: require("@/assets/img/caseyjsj1.png"),
              info: [
                {
                  label: "asasa",
                  value:
                    "现系统存储的文件档案类型20多种，文件条目900多万，文件总量40T。同时系统打通了与DMS、PIP、AE平台、大数据平台、业主平台、公文系统的接口，实现公司文档资源的汇聚与管控。",
                },
              ],
            },
            {
              img: require("@/assets/img/caseyjsj2.png"),
              info: [
                {
                  label: "asasa",
                  value:
                    "在数据集中的基础上进行文档的管理、控制、分发等操作，实现全公司文档资源管理中心。",
                },
              ],
            },
            {
              img: require("@/assets/img/caseyjsj3.png"),
              info: [
                {
                  label: "asasa",
                  value:
                    "院内已经基本实现EPCS全过程的数字化管理，为全面实现数字化的移交提供了充分的基础。",
                },
              ],
            },
          ],
        },
      ],
      activeIndex: "1",
      header_Animate: false,
      servely_Animate: false,
      servefield_Animate: false,
      partner_Animate: false,
      aboutusl_Animate: false,
      aboutusr_Animate: false,
      serve_Animate: false,
      items: [
        {
          image: require("@/assets/img/wxbg1.jpg"),
          title: "标题一",
          description: "描述一",
        },
        {
          image: require("@/assets/img/wxbg2.jpg"),
          title: "标题二",
          description: "描述二",
        },
        {
          image: require("@/assets/img/wxbg3.jpg"),
          title: "标题三",
          description: "描述三",
        },
      ],
    };
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    //鼠标移入
    enter(v) {
      if (v == 1) {
        this.iswechatimg = true;
      }
      if (v == 2) {
        this.iswechatimg2 = true;
      }
      if (v == 3) {
        this.iswechatimg3 = true;
      }
      if (v == 4) {
        this.iswechatimg4 = true;
      }
    },
    //鼠标移出
    leave(v) {
      if (v == 1) {
        this.iswechatimg = false;
      }
      if (v == 2) {
        this.iswechatimg2 = false;
      }
      if (v == 3) {
        this.iswechatimg3 = false;
      }
      if (v == 4) {
        this.iswechatimg4 = false;
      }
    },
    // 滚动事件
    scrollToTop() {
      !this.isElementNotInViewport(this.$refs.header)
        ? (this.header_Animate = true)
        : "";
      !this.isElementNotInViewport(this.$refs.serve)
        ? (this.serve_Animate = true)
        : "";
      !this.isElementNotInViewport(this.$refs.servely)
        ? (this.servely_Animate = true)
        : "";
      !this.isElementNotInViewport(this.$refs.servefield)
        ? (this.servefield_Animate = true)
        : "";
      !this.isElementNotInViewport(this.$refs.partner)
        ? (this.partner_Animate = true)
        : "";
      !this.isElementNotInViewport(this.$refs.aboutusl)
        ? (this.aboutusl_Animate = true)
        : "";
      !this.isElementNotInViewport(this.$refs.aboutusr)
        ? (this.aboutusr_Animate = true)
        : "";
    },
    isElementNotInViewport(el) {
      if (el) {
        let rect = el.getBoundingClientRect();
        // console.log(rect,'555555');
        return (
          rect.top >=
            (window.innerHeight || document.documentElement.clientHeight) ||
          rect.bottom <= 20
        );
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    //菜单栏点击滚动到相应模块
    linkswiper() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    linkserve() {
      window.scrollTo({
        top: 730,
        left: 0,
        behavior: "smooth",
      });
    },

    linkcase() {
      // console.log(555);
      // let target = document.getElementById('case');
      //   let parent = document.getElementById('nav');
      // // parent.scrollTop = target.offsetTop-parent.offsetTop;
      //  parent.scrollTo(0, target.offsetTop - parent.offsetTop);
      // document.getElementById('case').scrollIntoView({ behavior: "smooth"});
      window.scrollTo({
        top: 1600,
        left: 0,
        behavior: "smooth",
      });
    },
    linkabout() {
      window.scrollTo({
        top: 2350,
        left: 0,
        behavior: "smooth",
      });
    },
    link() {
      window.scrollTo({
        top: 3000,
        left: 0,
        behavior: "smooth",
      });
    },
    linkclass(){
  window.scrollTo({
        top: 3650,
        left: 0,
        behavior: "smooth",
      });
    },
    //打开案例详情
    openImg(v) {
      // console.log(v, "打开图片");
      this.showViewer = true;
      this.srcList = this.ClassCaseList[v].srcList;
    },
    closeViewer() {
      this.showViewer = false;
    },
  },
};
</script>
<style lang="less" scoped>
//媒体查询
//页面宽度小于等于300px时，背景变蓝色。
.Homepage {

   @media screen and (max-width: 1024px) {
    overflow-x: scroll;
  }
}
   .nav-box .container{
      @media screen and (max-width: 1024px) {
    width: 900px;
  }
   
   }
 .menu{
    .el-menu--horizontal>.el-menu-item {
       @media screen and (max-width: 1024px) {
         font-size: 12px;
  }
   
    }
  
  }
.aboutus .leftimg {
  @media screen and (min-width: 1024px) and (max-width: 1840px) {
    width: 800px !important;
  }
   @media screen and (max-width:1024px)  {
    width: 600px !important;
  }
}
.aboutus .infobox .overbox .content {
 @media screen and (max-width: 1024px)  {
   font-size:.7em 
  }
         @media screen and (min-width: 1024px)and (max-width: 1280px) {
    font-size: .8em;
}
}
.serve .main .flexlist .box .box-title {
       @media screen and (max-width: 1024px) {
      font-size: 1.4em;
          height: 40px;
  }
}
.serve .main .content{
         @media screen and (max-width: 1024px) {
      font-size: 12px;
      line-height: 20px;
   
  }
}
.serve .main .flexlist .box {
  @media screen and (min-width: 1024px) and (max-width: 1840px) {
    height: 15vw;
  }
         @media screen and (min-width: 1024px)and (max-width: 1280px) {
    font-size: .9em;
}
   @media screen and (max-width: 1024px) {
    font-size: .7em;
    height: 200px;
  }
}
.case .main .boxlist .box{
      @media screen and (max-width: 1024px) {
  height: 30em;
}
}
.case .main .boxlist .box .overbox p {
       @media screen and (min-width: 1024px)and (max-width: 1280px) {
    font-size: .9em;
}
     @media screen and (max-width: 1024px) {
    font-size: .7em;
}
  }
.case .main .boxlist .box .overbox h3 {
     @media screen and (max-width: 1025px) {
    font-size: 1em;
}

}
.whychoose .content{
        @media screen and (max-width: 1024px) {
    font-size: 12px;
}
}
.whychoose .content .left p {
      @media screen and (max-width: 1024px) {
    font-size: 16px;
}
}
.whychoose .content .right p {
      @media screen and (max-width: 1024px) {
    font-size: 16px;
}
}
.footer .title {
       @media screen and (max-width: 1024px) {
    width: 900px;
     font-size: .7em;
}

}
.cont-form-box {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 24px;
  padding-bottom: 4px;
  margin-bottom: 20px;
}
.ls-animationed > * {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}
/*从左到右进入*/
.fadeInLeft > * {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: fadeInleft;
  -webkit-animation-name: fadeInleft;
}
.fadeInRight > * {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: fadeInRight;
  -webkit-animation-name: fadeInRight;
}
.ls-animation > * {
  transform: perspective(400px) rotateX(90deg);
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  opacity: 0;
}
@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 1000px, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeInleft {
  from {
    opacity: 0;
    -webkit-transform: translate(1500px, 0);
    transform: translate(1500px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
  }
}

/*从左到右进入*/
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate(-1500px, 0);
    transform: translate(-1500px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
  }
}
</style>
